import envLocation from './utils/locationHelper';
const config = window.config();

export const {
  ENABLE_ORG_MANAGEMENT,
  ENABLE_MANAGE_ACCESS_INVITE,
  ENABLE_REBRANDING_PHASE_1,
  LIFEWAY_ACCOUNT_URL: LIFEWAY_ACCOUNTS_URL,
  PCI_FORM_URL,
  LIFEWAY_COM_URL,
  ENABLE_INTERCOM,
  ENABLE_CONTENTSQUARE,
  ENABLE_FULLSTORY,
  ENABLE_REORDER,
  ENABLE_AUTOSHIP_ON_ORDER_DETAILS,
  ENABLE_PURCHASERS_GROUP,
  ENABLE_PURCHASERS_WHATS_NEW,
  ENABLE_PAYPAL_FOR_AUTOSHIP,
  ENABLE_AUTOSHIP_ADD_ITEMS,
  ENABLE_APPROVED_PURCHASERS_PROMPT,
  ENABLE_DEFAULT_BILLING_ADDRESS,
  ENABLE_DEFAULT_PAYMENT_METHOD,
  ENABLE_MOBILE_DRAWER,
  ROLES,
  PERMISSIONS,
  ENABLE_REFACTORED_PAGINATED_ORGS,
  ENABLE_ADDRESS_SUGGESTIONS,
  ENABLE_URL_PAGINATION,
  ENABLE_DIGITAL_MEDIA_REACT,
  ENABLE_RQ_SUBSCRIPTIONS,
  ENABLE_ORDER_DETAIL_ORDER_SUMMARY,
  ENABLE_ORDER_DETAIL_HEADER_REFACTOR,
  ENABLE_ORDER_HISTORY_HEADER_MERGE,
  ENABLE_ORDER_HISTORY_REACT,
  ENABLE_SUBSCRIPTION_MODULE,
  ENABLE_STANDARDIZE_ADDRESS,
  ENABLE_MULTI_VIDEO_MODAL,
} = config;

export const INTERCOM_APP_ID = 'pvqbs0kp';

export const LOCATION_CHANGE = '@@router/LOCATION_CHANGE';

// Used to persist previous keys to allow for checking duplication
const whatsNewKeys = {
  hideAutoshipAndTaxExemption: 'hideAutoshipAndTaxExemption',
  hidePurchasersGroup: 'hidePurchasersGroup'
};

export const WHATS_NEW_CURRENT_HIDE_KEY = ENABLE_PURCHASERS_WHATS_NEW
  ? whatsNewKeys.hidePurchasersGroup
  : whatsNewKeys.hideAutoshipAndTaxExemption;

//Autoship Details
export const PATH_AUTOSHIP_DETAIL = `${LIFEWAY_COM_URL}/en/special-emphasis/autoship`;

// Subscription Details
export const PATH_SUBSCRIPTION_DETAIL = `${LIFEWAY_COM_URL}/en/product/ministry-grid-unlimited-annual-church-subscription-P005813997`

// Braintree
export const CREATE_BRAINTREE_CLIENT_INSTANCE_PENDING = 'Braintree/CREATE_BRAINTREE_CLIENT_INSTANCE_PENDING';
export const CREATE_BRAINTREE_CLIENT_INSTANCE_SUCCESS = 'Braintree/CREATE_BRAINTREE_CLIENT_INSTANCE_SUCCESS';
export const CREATE_BRAINTREE_CLIENT_INSTANCE_ERROR = 'Braintree/CREATE_BRAINTREE_CLIENT_INSTANCE_ERROR';
export const TOKENIZE_BRAINTREE_BANK_ACCOUNT_PENDING = 'Braintree/TOKENIZE_BRAINTREE_BANK_ACCOUNT_PENDING';
export const TOKENIZE_BRAINTREE_BANK_ACCOUNT_SUCCESS = 'Braintree/TOKENIZE_BRAINTREE_BANK_ACCOUNT_SUCCESS';
export const TOKENIZE_BRAINTREE_BANK_ACCOUNT_ERROR = 'Braintree/TOKENIZE_BRAINTREE_BANK_ACCOUNT_ERROR';

export const FETCH_ACH_PAYMENT_METHODS_PENDING = 'Invoice/FETCH_ACH_PAYMENT_METHODS_PENDING';
export const FETCH_ACH_PAYMENT_METHODS_SUCCESS = 'Invoice/FETCH_ACH_PAYMENT_METHODS_SUCCESS';
export const FETCH_ACH_PAYMENT_METHODS_ERROR = 'Invoice/FETCH_ACH_PAYMENT_METHODS_ERROR';

export const DELETE_ACH_PAYMENT_METHOD_CONFIRM = 'Invoice/DELETE_ACH_PAYMENT_METHOD_CONFIRM';
export const DELETE_ACH_PAYMENT_METHOD_PENDING = 'Invoice/DELETE_ACH_PAYMENT_METHOD_PENDING';
export const DELETE_ACH_PAYMENT_METHOD_SUCCESS = 'Invoice/DELETE_ACH_PAYMENT_METHOD_SUCCESS';
export const DELETE_ACH_PAYMENT_METHOD_ERROR = 'Invoice/DELETE_ACH_PAYMENT_METHOD_ERROR';
export const ADD_ACH_PAYMENT_METHOD_PENDING = 'Invoice/ADD_ACH_PAYMENT_METHOD_PENDING';
export const ADD_ACH_PAYMENT_METHOD_SUCCESS = 'Invoice/ADD_ACH_PAYMENT_METHOD_SUCCESS';
export const ADD_ACH_PAYMENT_METHOD_ERROR = 'Invoice/ADD_ACH_PAYMENT_METHOD_ERROR';
export const SET_ACH_PAYMENT_DEFAULT_METHOD_PENDING = 'Invoice/SET_ACH_PAYMENT_DEFAULT_METHOD_PENDING';
export const SET_ACH_PAYMENT_DEFAULT_METHOD_SUCCESS = 'Invoice/SET_ACH_PAYMENT_DEFAULT_METHOD_SUCCESS';
export const SET_ACH_PAYMENT_DEFAULT_METHOD_ERROR = 'Invoice/SET_ACH_PAYMENT_DEFAULT_METHOD_ERROR';

export const ACH_MANDATE_TEXT = 'By clicking "Submit", I authorize Braintree, a service of PayPal, on behalf of Lifeway (i) to verify my bank account information using bank information and consumer reports and (ii) to debit my bank account.';

export const SUBMIT_ACH_INVOICE_PAYMENT_PENDING = 'Invoice/SUBMIT_ACH_INVOICE_PAYMENT_PENDING';
export const SUBMIT_ACH_INVOICE_PAYMENT_SUCCESS = 'Invoice/SUBMIT_ACH_INVOICE_PAYMENT_SUCCESS';
export const SUBMIT_ACH_INVOICE_PAYMENT_ERROR = 'Invoice/SUBMIT_ACH_INVOICE_PAYMENT_ERROR';
export const RESET_ACH_PAYMENT_INFO = 'Invoice/RESET_ACH_PAYMENT_INFO';

export const FETCH_INVOICE_PDF_PENDING = 'Invoice/FETCH_INVOICE_PDF_PENDING';
export const FETCH_INVOICE_PDF_SUCCESS = 'Invoice/FETCH_INVOICE_PDF_SUCCESS';
export const FETCH_INVOICE_PDF_ERROR = 'Invoice/FETCH_INVOICE_PDF_ERROR';

export const ENV_LOCATION = envLocation();
export const AUTH_ROUTE = '/oauth2callback';
export const AUTH_REDIRECT_URL = ENV_LOCATION + AUTH_ROUTE;
export const AUTH_REDIRECT_QUERY_STRING = `redirectUrl=${AUTH_REDIRECT_URL}`;
export const PATH_PERSONAL_ACCOUNT = '/';
export const PATH_DASHBOARD = '/account-home';
export const PATH_PROFILE = '/profile';
export const PATH_EDIT_PERSONAL_INFO = `${PATH_PROFILE}/edit/personal-info`;
export const PATH_EDIT_EMAIL = `${PATH_PROFILE}/edit/email`;
export const PATH_EDIT_PASSWORD = `${PATH_PROFILE}/edit/password`;
export const PATH_USER_ADDRESS_MANAGEMENT = '/shipping-addresses';
export const PATH_GIFT_CARDS = '/payment-methods/gift-cards';
export const PATH_MEDIA_CONTENT = '/media-content';
export const PATH_DIGITAL_MEDIA = '/digital-media';
export const PATH_SUBSCRIPTIONS = '/subscriptions';
export const PATH_SUBSCRIPTIONS_EDIT_PAYMENT = `${PATH_SUBSCRIPTIONS}/edit/payment`;
export const PATH_REDEEM_CODE = '/redeem';
export const PATH_ADD_PHONE = `${PATH_PROFILE}/add/phone`;
export const PATH_EDIT_PHONE = `${PATH_PROFILE}/edit/phone`;
export const PATH_DELETE_PHONE = `${PATH_PROFILE}/delete/phone`;
export const PATH_ADD_SHIPPING_ADDRESS = `${PATH_USER_ADDRESS_MANAGEMENT}/add/address`;
export const PATH_EDIT_SHIPPING_ADDRESS = `${PATH_USER_ADDRESS_MANAGEMENT}/edit/address`;
export const PATH_DELETE_SHIPPING_ADDRESS = `${PATH_USER_ADDRESS_MANAGEMENT}/delete/address`;
export const PATH_CREDIT_CARDS = '/payment-methods/credit-cards';
export const PATH_PAYMENT_METHODS = '/payment-methods';
export const PATH_ADD_CREDIT_CARD = `${PATH_CREDIT_CARDS}/add/card`;
export const PATH_EDIT_CREDIT_CARD = `${PATH_CREDIT_CARDS}/edit/card`;
export const PATH_DELETE_CREDIT_CARD = `${PATH_CREDIT_CARDS}/delete/card`;
export const PATH_ORG_MGMT = '/org-management';
export const PATH_MANAGE_ACCESS = '/manage-access';
export const PATH_MANAGE_ACCESS_SIMULCAST = '/manage-access/simulcasts';
export const PATH_MANAGE_ACCESS_EBOOKS = '/manage-access/ebooks';
export const PATH_MANAGE_ACCESS_VIDEOS = '/manage-access/videos';
export const PATH_ACCS = `${PATH_ORG_MGMT}/billing-accounts`;
export const PATH_ORG_ACCT_SHOW = `${PATH_ORG_MGMT}/billing-account`;
export const PATH_BILLING_ACCOUNT = `${PATH_ORG_ACCT_SHOW}/:billingAccountId`;
export const PATH_BA_ACCOUNT_ACTIVITY = `${PATH_BILLING_ACCOUNT}/account-activity`;
export const PATH_BA_BILLING_ACTIVITY = `${PATH_BILLING_ACCOUNT}/billing-activity`;
export const PATH_BA_ORDER_HISTORY = `${PATH_BILLING_ACCOUNT}/order-history`;
export const PATH_ORG_ORDER_HISTORY = `${PATH_ORG_ACCT_SHOW}/${PATH_ORDER_HISTORY}`;
export const PATH_DELETE_BILLING_ACCT = `${PATH_ORG_MGMT}/delete/billing-account`;
export const PATH_ORDER_HISTORY = '/order-history';
export const PATH_DIGITAL_CONTENT = '/digital-content';
export const PATH_ACCOUNTS_ACTIVITY = '/account-activity';
export const PATH_BILLING_ACTIVITY = '/billing-activity';
export const PATH_VIEW_GIFT_CARD = '/view-gift-card';
export const PATH_REDIRECT = '/redirect';
export const PATH_EXTERNAL_REDIRECT = `${ENV_LOCATION}${PATH_REDIRECT}`;
export const PATH_PDF_VIEW = '/pdf-view';
export const PATH_LOGGED_OUT = '/logged-out';
export const PATH_LOGIN_REDIRECT = `${PATH_REDIRECT}/login`;
export const PATH_LOGOUT_CALLBACK = '/callback/logout';
export const PATH_INVITE_CANCELLED = '/invite-cancelled';
export const INVITE_CANCELED_CALLBACK_URL = `${ENV_LOCATION}${PATH_INVITE_CANCELLED}`;
export const INVITE_ORG_CALLBACK_URL = `${ENV_LOCATION}/invite/organization/{inviteId}`;
export const INVITE_SEAT_CALLBACK_URL = `${ENV_LOCATION}/invite/license/{inviteId}`;
export const PATH_AUTOSHIP = '/autoship';
export const PATH_AUTOSHIP_DETAILS = '/autoship/:autoshipId';

export const PATH_ORG = '/organization/:organizationId';
export const ORG_PATHS = {
  BASE: PATH_ORG,
  PROFILE: `${PATH_ORG}/profile`,
  DASHBOARD: `${PATH_ORG}/account-home`,
  ORDER_HISTORY_BASE: `${PATH_ORG}/order-history`,
  ORDER_HISTORY: `${PATH_ORG}/order-history/:billingAccountId`,
  ORDER_DETAILS: `${PATH_ORG}/order-history/:billingAccountId/order/:orderId`,
  AUTOSHIP: `${PATH_ORG}/autoship`,
  AUTOSHIP_DETAILS: `${PATH_ORG}/autoship/:autoshipId`,
  ACCOUNT_ACTIVITY_BASE: `${PATH_ORG}/account-activity`,
  BILLING_ACTIVITY_BASE: `${PATH_ORG}/billing-activity`,
  ACCOUNT_ACTIVITY: `${PATH_ORG}/account-activity/:billingAccountId`,
  BILLING_ACTIVITY: `${PATH_ORG}/billing-activity/:billingAccountId`,
  ACCOUNT_SETTINGS_BASE: `${PATH_ORG}/account-settings`,
  BILLING_SETTINGS_BASE: `${PATH_ORG}/billing-settings`,
  ACCOUNT_SETTINGS: `${PATH_ORG}/account-settings/:billingAccountId`,
  BILLING_SETTINGS: `${PATH_ORG}/billing-settings/:billingAccountId`,
  GROUPS: `${PATH_ORG}/groups`,
  ADDRESS_MANAGEMENT: `${PATH_ORG}/shipping-addresses`,
  SUBSCRIPTIONS: `${PATH_ORG}/subscriptions`
};

export const INV_PATHS = {
  ORG_INVITE: '/invite/organization/:inviteId',
  LICENSE_INVITE: '/invite/license/:inviteId'
};

export const APP_ON_INIT = 'App/ON_INIT';
export const APP_ON_RESIZE = 'App/ON_RESIZE';
export const APP_ON_ERROR = 'App/ON_ERROR';
export const APP_NAV_TOGGLE = 'App/NAV_TOGGLE';
export const APP_UPDATE_BREAKPOINTS = 'App/UPDATE_BREAKPOINTS';
export const APP_LOG_OUT_PENDING = 'App/LOG_OUT_PENDING';
export const APP_LOG_OUT_SUCCESS = 'App/LOG_OUT_SUCCESS';
export const APP_LOG_IN_PENDING = 'App/LOG_IN_PENDING';
export const APP_LOG_IN_SUCCESS = 'App/LOG_IN_SUCCESS';
export const APP_LOG_OUT_ERROR = 'App/LOG_OUT_ERROR';
export const APP_TOGGLE_FORM = 'App/TOGGLE_FORM';
export const APP_TOGGLE_MODAL = 'App/TOGGLE_MODAL';
export const APP_TRIGGER_MODAL = 'App/TRIGGER_MODAL';
export const APP_CONFIRM_MODAL = 'App/CONFIRM_MODAL';
export const APP_CANCEL_MODAL = 'App/CANCEL_MODAL';
export const APP_NAVIGATE_AWAY = 'App/NAVIGATE_AWAY';
export const APP_SET_BANNER_STATE = 'App/SET_BANNER_STATE';
export const APP_CLEAR_BANNER_STATE = 'App/CLEAR_BANNER_STATE';
export const TOGGLE_PROCESSED_ITEM = 'App/TOGGLE_PROCESSED_ITEM';
export const SET_SEARCH_RESULTS = 'App/SET_SEARCH_RESULTS';

export const LOGGED_IN_TO_TRUE = 'App/LOGGED_IN_TO_TRUE';
export const LOGGED_IN_TO_FALSE = 'App/LOGGED_IN_TO_FALSE';
export const LIFEWAYACCOUNT_LOGIN = 'App/LIFEWAYACCOUNT_LOGIN';
export const LIFEWAYACCOUNT_LOGOUT = 'App/LIFEWAYACCOUNT_LOGOUT';
export const SSO_READY_STATE = 'App/SSO_READY_STATE';
export const SET_IS_IMPERSONATION_SESSION = 'App/SET_IS_IMPERSONATION_SESSION';

export const APP_REAUTHENTICATE_PENDING = 'App/REAUTHENTICATE_PENDING';


export const HOME_DATA_PENDING = 'Home/DATA_PENDING';
export const HOME_DATA_SUCCESS = 'Home/DATA_SUCCESS';
export const HOME_DATA_ERROR = 'Home/DATA_ERROR';

export const PLACEHOLDER_SET_PAGE_TITLES = 'Placeholder/SET_PAGE_TITLES';

export const SET_SELECTED_PROFILE = 'Profile/SET_SELECTED_PROFILE';

// Profile Page
export const FETCH_USER_DATA_PENDING = 'Profile/FETCH_USER_DATA_PENDING';
export const FETCH_USER_DATA_SUCCESS = 'Profile/FETCH_USER_DATA_SUCCESS';
export const FETCH_USER_DATA_ERROR = 'Profile/FETCH_USER_DATA_ERROR';
export const PROFILE_ADD_PHONE_PENDING = 'Profile/ADD_PHONE_PENDING';
export const PROFILE_ADD_PHONE_SUCCESS = 'Profile/ADD_PHONE_SUCCESS';
export const PROFILE_ADD_PHONE_ERROR = 'Profile/ADD_PHONE_ERROR';
export const PROFILE_UPDATE_PHONE_PENDING = 'Profile/UPDATE_PHONE_PENDING';
export const PROFILE_UPDATE_PHONE_SUCCESS = 'Profile/UPDATE_PHONE_SUCCESS';
export const PROFILE_UPDATE_PHONE_ERROR = 'Profile/UPDATE_PHONE_ERROR';
export const PROFILE_DELETE_PHONE_CONFIRM = 'Profile/DELETE_PHONE_CONFIRM';
export const PROFILE_DELETE_PHONE_PENDING = 'Profile/DELETE_PHONE_PENDING';
export const PROFILE_DELETE_PHONE_SUCCESS = 'Profile/DELETE_PHONE_SUCCESS';
export const PROFILE_DELETE_PHONE_ERROR = 'Profile/DELETE_PHONE_ERROR';
export const PROFILE_GET_PHONE_PENDING = 'Profile/GET_PHONE_PENDING';
export const PROFILE_GET_PHONE_SUCCESS = 'Profile/GET_PHONE_SUCCESS';
export const PROFILE_GET_PHONE_ERROR = 'Profile/GET_PHONE_ERROR';
export const POST_PERSONAL_INFO_PENDING = 'Profile/POST_PERSONAL_INFO_PENDING';
export const POST_PERSONAL_INFO_SUCCESS = 'Profile/POST_PERSONAL_INFO_SUCCESS';
export const POST_PERSONAL_INFO_ERROR = 'Profile/POST_PERSONAL_INFO_ERROR';
export const PROFILE_UPDATE_ACCOUNT_PENDING = 'Profile/UPDATE_ACCOUNT_PENDING';
export const PROFILE_UPDATE_EMAIL_PENDING = 'Profile/UPDATE_EMAIL_PENDING';
export const PROFILE_UPDATE_EMAIL_SUCCESS = 'Profile/UPDATE_EMAIL_SUCCESS';
export const PROFILE_UPDATE_EMAIL_ERROR = 'Profile/UPDATE_EMAIL_ERROR';
export const PROFILE_UPDATE_PASSWORD_PENDING = 'Profile/UPDATE_PASSWORD_PENDING';
export const PROFILE_UPDATE_PASSWORD_SUCCESS = 'Profile/UPDATE_PASSWORD_SUCCESS';
export const PROFILE_UPDATE_PASSWORD_ERROR = 'Profile/UPDATE_PASSWORD_ERROR';
export const FETCH_3RD_PARTY_IDP_PENDING = 'Profile/FETCH_3RD_PARTY_IDP_PENDING';
export const FETCH_3RD_PARTY_IDP_SUCCESS = 'Profile/FETCH_3RD_PARTY_IDP_SUCCESS';
export const FETCH_3RD_PARTY_IDP_ERROR = 'Profile/FETCH_3RD_PARTY_IDP_ERROR';
export const REMOVE_3RD_PARTY_IDP_CONFIRM = 'Profile/REMOVE_3RD_PARTY_IDP_CONFIRM';
export const REMOVE_3RD_PARTY_IDP_PENDING = 'Profile/REMOVE_3RD_PARTY_IDP_PENDING';
export const REMOVE_3RD_PARTY_IDP_SUCCESS = 'Profile/REMOVE_3RD_PARTY_IDP_SUCCESS';
export const REMOVE_3RD_PARTY_IDP_ERROR = 'Profile/REMOVE_3RD_PARTY_IDP_ERROR';

export const GET_VOD_DEVICES_PENDING = 'Profile/GET_VOD_DEVICES_PENDING';
export const GET_VOD_DEVICES_SUCCESS = 'Profile/GET_VOD_DEVICES_SUCCESS';
export const GET_VOD_DEVICES_ERROR = 'Profile/GET_VOD_DEVICES_ERROR';
export const RENAME_VOD_DEVICE_PENDING = 'Profile/RENAME_VOD_DEVICE_PENDING';
export const RENAME_VOD_DEVICE_SUCCESS = 'Profile/RENAME_VOD_DEVICE_SUCCESS';
export const RENAME_VOD_DEVICE_ERROR = 'Profile/RENAME_VOD_DEVICE_ERROR';
export const REMOVE_VOD_DEVICE_PENDING = 'Profile/REMOVE_VOD_DEVICE_PENDING';
export const REMOVE_VOD_DEVICE_SUCCESS = 'Profile/REMOVE_VOD_DEVICE_SUCCESS';
export const REMOVE_VOD_DEVICE_ERROR = 'Profile/REMOVE_VOD_DEVICE_ERROR';
export const REMOVE_DEVICE_CONFIRM = 'Profile/REMOVE_DEVICE_CONFIRM';

export const GET_WEBAUTHN_PUBLIC_KEYS_PENDING = 'Profile/GET_WEBAUTHN_PUBLIC_KEYS_PENDING';
export const GET_WEBAUTHN_PUBLIC_KEYS_SUCCESS = 'Profile/GET_WEBAUTHN_PUBLIC_KEYS_SUCCESS';
export const GET_WEBAUTHN_PUBLIC_KEYS_ERROR = 'Profile/GET_WEBAUTHN_PUBLIC_KEYS_ERROR';

export const REMOVE_WEBAUTHN_PUBLIC_KEY_CONFIRM = 'Profile/REMOVE_WEBAUTHN_PUBLIC_KEY_CONFIRM';
export const REMOVE_WEBAUTHN_PUBLIC_KEY_PENDING = 'Profile/REMOVE_WEBAUTHN_PUBLIC_KEY_PENDING';
export const REMOVE_WEBAUTHN_PUBLIC_KEY_SUCCESS = 'Profile/REMOVE_WEBAUTHN_PUBLIC_KEY_SUCCESS';
export const REMOVE_WEBAUTHN_PUBLIC_KEY_ERROR = 'Profile/REMOVE_WEBAUTHN_PUBLIC_KEY_ERROR';

export const RENAME_WEBAUTHN_PUBLIC_KEY_PENDING = 'Profile/RENAME_WEBAUTHN_PUBLIC_KEY_PENDING';
export const RENAME_WEBAUTHN_PUBLIC_KEY_SUCCESS = 'Profile/RENAME_WEBAUTHN_PUBLIC_KEY_SUCCESS';
export const RENAME_WEBAUTHN_PUBLIC_KEY_ERROR = 'Profile/RENAME_WEBAUTHN_PUBLIC_KEY_ERROR';

export const GET_USER_PENDING = 'LifewayUser/GET_USER_PENDING';
export const GET_USER_SUCCESS = 'LifewayUser/GET_USER_SUCCESS';
export const GET_USER_ERROR = 'LifewayUser/GET_USER_ERROR';

export const FETCH_INVITATIONS = 'Invites/FETCH';
export const FETCH_INVITATIONS_SUCCESS = 'Invites/FETCH_SUCCESS';
export const FETCH_INVITATIONS_ERROR = 'Invites/FETCH_ERROR';
export const FETCH_INVITATION = 'Invites/FETCH_SINGLE';
export const FETCH_INVITATION_SUCCESS = 'Invites/FETCH_SINGLE_SUCCESS';
export const FETCH_INVITATION_ERROR = 'Invites/FETCH_SINGLE_ERROR';

export const FETCH_ORG_INVITATIONS = 'Invites/FETCH_BY_ORG';
export const FETCH_ORG_INVITATIONS_SUCCESS = 'Invites/FETCH_BY_ORG_SUCCESS';
export const FETCH_ORG_INVITATIONS_ERROR = 'Invites/FETCH_BY_ORG_ERROR';

export const FETCH_LICENSE_INVITATIONS = 'Invites/FETCH_BY_LICENSE';
export const FETCH_LICENSE_INVITATIONS_SUCCESS = 'Invites/FETCH_BY_LICENSE_SUCCESS';
export const FETCH_LICENSE_INVITATIONS_ERROR = 'Invites/FETCH_BY_LICENSE_ERROR';

export const SEND_GROUP_INVITE = 'Invites/SEND_GROUP';
export const SEND_GROUP_INVITE_SUCCESS = 'Invites/SEND_GROUP_SUCCESS';
export const SEND_GROUP_INVITE_ERROR = 'Invites/SEND_GROUP_ERROR';

export const SEND_LICENSE_INVITE_PENDING = 'Invites/SEND_LICENSE_PENDING';
export const SEND_LICENSE_INVITE_SUCCESS = 'Invites/SEND_LICENSE_SUCCESS';
export const SEND_LICENSE_INVITE_ERROR = 'Invites/SEND_LICENSE_ERROR';

export const RESEND_INVITE = 'Invites/RESEND';
export const RESEND_INVITE_SUCCESS = 'Invites/RESEND_SUCCESS';
export const RESEND_INVITE_ERROR = 'Invites/RESEND_ERROR';
export const RESPOND_TO_INVITE = 'Invites/RESPOND';
export const RESPOND_TO_INVITE_SUCCESS = 'Invites/RESPOND_SUCCESS';
export const RESPOND_TO_INVITE_ERROR = 'Invites/RESPOND_ERROR';
export const CANCEL_INVITE_CONFIRMATION = 'Invites/CANCEL_INVITE_CONFIRMATION';
export const CANCEL_INVITE_PENDING = 'Invites/CANCEL_INVITE_PENDING';
export const CANCEL_INVITE_SUCCESS = 'Invites/CANCEL_INVITE_SUCCESS';
export const CANCEL_INVITE_ERROR = 'Invites/CANCEL_INVITE_ERROR';

// Subscription Page
export const GET_SUBSCRIPTIONS_PENDING = 'Subscriptions/GET_SUBSCRIPTIONS_PENDING';
export const GET_SUBSCRIPTIONS_SUCCESS = 'Subscriptions/GET_SUBSCRIPTIONS_SUCCESS';
export const GET_SUBSCRIPTIONS_ERROR = 'Subscriptions/GET_SUBSCRIPTIONS_ERROR';
export const SET_SUBSCRIPTIONS_LOADING = 'Subscriptions/SET_SUBSCRIPTIONS_LOADING';

export const GET_ORG_SUBSCRIPTIONS_PENDING = 'Subscriptions/GET_ORG_SUBSCRIPTIONS_PENDING';
export const GET_ORG_SUBSCRIPTIONS_SUCCESS = 'Subscriptions/GET_ORG_SUBSCRIPTIONS_SUCCESS';
export const GET_ORG_SUBSCRIPTIONS_ERROR = 'Subscriptions/GET_ORG_SUBSCRIPTIONS_ERROR';
export const SET_ORG_SUBSCRIPTIONS_LOADING = 'Subscriptions/SET_ORG_SUBSCRIPTIONS_LOADING';

export const GET_SUBSCRIPTION_DETAILS_PENDING = 'Subscriptions/GET_SUBSCRIPTION_DETAILS_PENDING';
export const GET_SUBSCRIPTION_DETAILS_SUCCESS = 'Subscriptions/GET_SUBSCRIPTION_DETAILS_SUCCESS';
export const GET_SUBSCRIPTION_DETAILS_ERROR = 'Subscriptions/GET_SUBSCRIPTION_DETAILS_ERROR';

export const UPDATE_SUBSCRIPTIONS_PAYMENT_PENDING = 'Subscriptions/UPDATE_SUBSCRIPTIONS_PAYMENT_PENDING';
export const UPDATE_SUBSCRIPTIONS_PAYMENT_SUCCESS = 'Subscriptions/UPDATE_SUBSCRIPTIONS_PAYMENT_SUCCESS';
export const UPDATE_SUBSCRIPTIONS_PAYMENT_ERROR = 'Subscriptions/UPDATE_SUBSCRIPTIONS_PAYMENT_ERROR';

export const UPDATE_SUBSCRIPTION_STATUS_PENDING = 'Subscriptions/UPDATE_SUBSCRIPTION_STATUS_PENDING';
export const UPDATE_SUBSCRIPTION_STATUS_SUCCESS = 'Subscriptions/UPDATE_SUBSCRIPTION_STATUS_SUCCESS';
export const UPDATE_SUBSCRIPTION_STATUS_ERROR = 'Subscriptions/UPDATE_STATUS_ERROR';

export const UPDATE_ORG_SUBSCRIPTION_CONTACT_PENDING = 'Subscriptions/UPDATE_ORG_SUBSCRIPTION_CONTACT_PENDING';
export const UPDATE_ORG_SUBSCRIPTION_CONTACT_SUCCESS = 'Subscriptions/UPDATE_ORG_SUBSCRIPTION_CONTACT_SUCCESS';
export const UPDATE_ORG_SUBSCRIPTION_CONTACT_ERROR = 'Subscriptions/UPDATE_ORG_SUBSCRIPTION_CONTACT_ERROR';

export const CANCEL_SUBSCRIPTION_PENDING = 'Subscriptions/CANCEL_SUBSCRIPTION_PENDING';
export const CANCEL_SUBSCRIPTION_SUCCESS = 'Subscriptions/CANCEL_SUBSCRIPTION_SUCCESS';
export const CANCEL_SUBSCRIPTION_ERROR = 'Subscriptions/CANCEL_SUBSCRIPTION_ERROR';

export const TRANSFER_SUBSCRIPTION_PENDING = 'Subscriptions/TRANSFER_SUBSCRIPTION_PENDING';
export const TRANSFER_SUBSCRIPTION_SUCCESS = 'Subscriptions/TRANSFER_SUBSCRIPTION_SUCCESS';
export const TRANSFER_SUBSCRIPTION_ERROR = 'Subscriptions/TRANSFER_SUBSCRIPTION_ERROR';

export const GET_CONTENTFUL_LINKS_PENDING = 'Contentful/GET_CONTENTFUL_LINKS_PENDING';
export const GET_CONTENTFUL_LINKS_SUCCESS = 'Contentful/GET_CONTENTFUL_LINKS_SUCCESS';
export const GET_CONTENTFUL_LINKS_ERROR = 'Contentful/GET_CONTENTFUL_LINKS_ERROR';

// Autoship Actions
export const GET_PERSONAL_AUTOSHIPS_PENDING = 'Autoships/GET_PERSONAL_AUTOSHIPS_PENDING';
export const GET_PERSONAL_AUTOSHIPS_SUCCESS = 'Autoships/GET_PERSONAL_AUTOSHIPS_SUCCESS';
export const GET_PERSONAL_AUTOSHIPS_ERROR = 'Autoships/GET_PERSONAL_AUTOSHIPS_ERROR';

export const GET_ORG_AUTOSHIPS_PENDING = 'Autoships/GET_ORG_AUTOSHIPS_PENDING';
export const GET_ORG_AUTOSHIPS_SUCCESS = 'Autoships/GET_ORG_AUTOSHIPS_SUCCESS';
export const GET_ORG_AUTOSHIPS_ERROR = 'Autoships/GET_ORG_AUTOSHIPS_ERROR';

export const GET_AUTOSHIP_DETAILS_PENDING = 'Autoships/GET_AUTOSHIP_DETAILS_PENDING';
export const GET_AUTOSHIP_DETAILS_SUCCESS = 'Autoships/GET_AUTOSHIP_DETAILS_SUCCESS';
export const GET_AUTOSHIP_DETAILS_ERROR = 'Autoships/GET_AUTOSHIP_DETAILS_ERROR';

export const CREATE_AUTOSHIP_PENDING = 'Autoships/CREATE_PENDING';
export const CREATE_AUTOSHIP_SUCCESS = 'Autoships/CREATE_SUCCESS';
export const CREATE_AUTOSHIP_ERROR = 'Autoships/CREATE_ERROR';

export const UPDATE_AUTOSHIP_SHIPPING_ADDRESS_PENDING = 'Autoships/UPDATE_AUTOSHIP_SHIPPING_ADDRESS_PENDING';
export const UPDATE_AUTOSHIP_SHIPPING_ADDRESS_SUCCESS = 'Autoships/UPDATE_AUTOSHIP_SHIPPING_ADDRESS_SUCCESS';
export const UPDATE_AUTOSHIP_SHIPPING_ADDRESS_ERROR = 'Autoships/UPDATE_AUTOSHIP_SHIPPING_ERROR';

export const UPDATE_AUTOSHIP_PAYMENT_METHOD_PENDING = 'Autoships/UPDATE_AUTOSHIP_PAYMENT_METHOD_PENDING';
export const UPDATE_AUTOSHIP_PAYMENT_METHOD_SUCCESS = 'Autoships/UPDATE_AUTOSHIP_PAYMENT_METHOD_SUCCESS';
export const UPDATE_AUTOSHIP_PAYMENT_METHOD_ERROR = 'Autoships/UPDATE_AUTOSHIP_PAYMENT_METHOD_ERROR';

export const UPDATE_AUTOSHIP_CONTACT_PENDING = 'Autoships/UPDATE_AUTOSHIP_CONTACT_PENDING';
export const UPDATE_AUTOSHIP_CONTACT_SUCCESS = 'Autoships/UPDATE_AUTOSHIP_CONTACT_SUCCESS';
export const UPDATE_AUTOSHIP_CONTACT_ERROR = 'Autoships/UPDATE_AUTOSHIP_CONTACT_ERROR';

export const GET_PRODUCT_AVAILABILITY_PENDING = 'Product/GET_AVAILABILITY_PENDING';
export const GET_PRODUCT_AVAILABILITY_SUCCESS = 'Product/GET_AVAILABILITY_SUCCESS';
export const GET_PRODUCT_AVAILABILITY_ERROR = 'Product/GET_AVAILABILITY_ERROR';

export const GET_PRODUCT_ALL_DETAILS_PENDING = 'Product/GET_ALL_DETAILS_PENDING';
export const GET_PRODUCT_ALL_DETAILS_SUCCESS = 'Product/GET_ALL_DETAILS_SUCCESS';
export const GET_PRODUCT_ALL_DETAILS_ERROR = 'Product/GET_ALL_DETAILS_ERROR';

export const FIND_PRODUCTS_REPLACEMENTS_PENDING = 'Product/FIND_PRODUCTS_REPLACEMENTS_PENDING';
export const FIND_PRODUCTS_REPLACEMENTS_SUCCESS = 'Product/FIND_PRODUCTS_REPLACEMENTS_SUCCESS';
export const FIND_PRODUCTS_REPLACEMENTS_ERROR = 'Product/FIND_PRODUCTS_REPLACEMENTS_ERROR';

export const GET_PRODUCT_ALL_DETAILS_FIND_PRODUCTS_REPLACEMENTS_PENDING = 'Product/GET_ALL_DETAILS_FIND_PRODUCTS_REPLACEMENTS_PENDING';
export const GET_PRODUCT_ALL_DETAILS_FIND_PRODUCTS_REPLACEMENTS_SUCCESS = 'Product/GET_ALL_DETAILS_FIND_PRODUCTS_REPLACEMENTS_SUCCESS';
export const GET_PRODUCT_ALL_DETAILS_FIND_PRODUCTS_REPLACEMENTS_ERROR = 'Product/GET_ALL_DETAILS_FIND_PRODUCTS_REPLACEMENTS_ERROR';

export const CANCEL_AUTOSHIP_PENDING = 'Autoships/CANCEL_AUTOSHIP_PENDING';
export const CANCEL_AUTOSHIP_SUCCESS = 'Autoships/CANCEL_AUTOSHIP_SUCCESS';
export const CANCEL_AUTOSHIP_ERROR = 'Autoships/CANCEL_AUTOSHIP_ERROR';

export const REMOVE_AUTOSHIP_ITEM_PENDING = 'Autoships/REMOVE_ITEM_PENDING';
export const REMOVE_AUTOSHIP_ITEM_SUCCESS = 'Autoships/REMOVE_ITEM_SUCCESS';
export const REMOVE_AUTOSHIP_ITEM_ERROR = 'Autoships/REMOVE_ITEM_ERROR';

export const CHANGE_AUTOSHIP_ITEM_QUANTITY_PENDING = 'Autoships/CHANGE_ITEM_QUANTITY_PENDING';
export const CHANGE_AUTOSHIP_ITEM_QUANTITY_SUCCESS = 'Autoships/CHANGE_ITEM_QUANTITY_SUCCESS';
export const CHANGE_AUTOSHIP_ITEM_QUANTITY_ERROR = 'Autoships/CHANGE_ITEM_QUANTITY_ERROR';

export const CHANGE_AUTOSHIP_FREQUENCY_PENDING = 'Autoships/CHANGE_AUTOSHIP_FREQUENCY_PENDING';
export const CHANGE_AUTOSHIP_FREQUENCY_SUCCESS = 'Autoships/CHANGE_AUTOSHIP_FREQUENCY_SUCCESS';
export const CHANGE_AUTOSHIP_FREQUENCY_ERROR = 'Autoships/CHANGE_AUTOSHIP_FREQUENCY_ERROR';

export const CHANGE_AUTOSHIP_NEXT_ORDER_DATE_PENDING = 'Autoships/CHANGE_AUTOSHIP_NEXT_ORDER_DATE_PENDING';
export const CHANGE_AUTOSHIP_NEXT_ORDER_DATE_SUCCESS = 'Autoships/CHANGE_AUTOSHIP_NEXT_ORDER_DATE_SUCCESS';
export const CHANGE_AUTOSHIP_NEXT_ORDER_DATE_ERROR = 'Autoships/CHANGE_AUTOSHIP_NEXT_ORDER_DATE_ERROR';

export const CHANGE_AUTOSHIP_NAME_PENDING = 'Autoships/CHANGE_AUTOSHIP_NAME_PENDING';
export const CHANGE_AUTOSHIP_NAME_SUCCESS = 'Autoships/CHANGE_AUTOSHIP_NAME_SUCCESS';
export const CHANGE_AUTOSHIP_NAME_ERROR = 'Autoships/CHANGE_AUTOSHIP_NAME_ERROR';

export const SKIP_AUTOSHIP_NEXT_ORDER_PENDING = 'Autoships/SKIP_NEXT_ORDER_PENDING';
export const SKIP_AUTOSHIP_NEXT_ORDER_SUCCESS = 'Autoships/SKIP_NEXT_ORDER_SUCCESS';
export const SKIP_AUTOSHIP_NEXT_ORDER_ERROR = 'Autoships/SKIP_NEXT_ORDER_ERROR';

export const SUBMIT_AUTOSHIP_ORDER_PENDING = 'Autoships/SUBMIT_AUTOSHIP_ORDER_PENDING';
export const SUBMIT_AUTOSHIP_ORDER_SUCCESS = 'Autoships/SUBMIT_AUTOSHIP_ORDER_SUCCESS';
export const SUBMIT_AUTOSHIP_ORDER_ERROR = 'Autoships/SUBMIT_AUTOSHIP_ORDERS_ERROR';

export const TRANSFER_AUTOSHIP_ITEM_TO_EXISTING_PENDING = 'Autoships/TRANSFER_ITEM_TO_EXISTING_PENDING';
export const TRANSFER_AUTOSHIP_ITEM_TO_EXISTING_SUCCESS = 'Autoships/TRANSFER_ITEM_TO_EXISTING_SUCCESS';
export const TRANSFER_AUTOSHIP_ITEM_TO_EXISTING_ERROR = 'Autoships/TRANSFER_ITEM_TO_EXISTING_ERROR';

export const TRANSFER_AUTOSHIP_ITEM_TO_EXISTING_WITH_QUANTITY_PENDING = 'Autoships/TRANSFER_ITEM_TO_EXISTING_WITH_QUANTITY_PENDING';
export const TRANSFER_AUTOSHIP_ITEM_TO_EXISTING_WITH_QUANTITY_SUCCESS = 'Autoships/TRANSFER_ITEM_TO_EXISTING_WITH_QUANTITY_SUCCESS';
export const TRANSFER_AUTOSHIP_ITEM_TO_EXISTING_WITH_QUANTITY_ERROR = 'Autoships/TRANSFER_ITEM_TO_EXISTING_WITH_QUANTITY_ERROR';

export const TRANSFER_AUTOSHIP_ITEM_TO_NEW_PENDING = 'Autoships/TRANSFER_ITEM_TO_NEW_PENDING';
export const TRANSFER_AUTOSHIP_ITEM_TO_NEW_SUCCESS = 'Autoships/TRANSFER_ITEM_TO_NEW_SUCCESS';
export const TRANSFER_AUTOSHIP_ITEM_TO_NEW_ERROR = 'Autoships/TRANSFER_ITEM_TO_NEW_ERROR';

export const ADD_AUTOSHIP_ITEMS_PENDING = 'Autoships/ADD_ITEMS_PENDING';
export const ADD_AUTOSHIP_ITEMS_SUCCESS = 'Autoships/ADD_ITEMS_SUCCESS';
export const ADD_AUTOSHIP_ITEMS_ERROR = 'Autoships/ADD_ITEMS_ERROR';

export const REACTIVATE_AUTOSHIP_PENDING = 'Autoships/REACTIVATE_PENDING';
export const REACTIVATE_AUTOSHIP_SUCCESS = 'Autoships/REACTIVATE_SUCCESS';
export const REACTIVATE_AUTOSHIP_ERROR = 'Autoships/REACTIVATE_ERROR';

// Shipping Addresses Page
export const STANDARDIZE_ADDRESS_PENDING = 'ShippingAddresses/GET_USER_SHIPPING_ADDRESSES_PENDING';
export const STANDARDIZE_ADDRESS_SUCCESS = 'ShippingAddresses/STANDARDIZE_ADDRESS_SUCCESS';
export const STANDARDIZE_ADDRESS_ERROR = 'ShippingAddresses/STANDARDIZE_ADDRESS_ERROR';
export const GET_SHIPPING_ADDRESSES_PENDING = 'ShippingAddresses/GET_SHIPPING_ADDRESSES_PENDING';
export const GET_SHIPPING_ADDRESSES_SUCCESS = 'ShippingAddresses/GET_SHIPPING_ADDRESSES_SUCCESS';
export const GET_SHIPPING_ADDRESSES_ERROR = 'ShippingAddresses/GET_SHIPPING_ADDRESSES_ERROR';
export const ADD_USER_SHIPPING_ADDRESS_PENDING = 'ShippingAddresses/ADD_USER_SHIPPING_ADDRESS_PENDING';
export const ADD_USER_SHIPPING_ADDRESS_SUCCESS = 'ShippingAddresses/ADD_USER_SHIPPING_ADDRESS_SUCCESS';
export const ADD_USER_SHIPPING_ADDRESS_ERROR = 'ShippingAddresses/ADD_USER_SHIPPING_ADDRESS_ERROR';
export const DELETE_USER_SHIPPING_ADDRESS_CONFIRM = 'ShippingAddresses/DELETE_USER_SHIPPING_ADDRESS_CONFIRM';
export const DELETE_USER_SHIPPING_ADDRESS_PENDING = 'ShippingAddresses/DELETE_USER_SHIPPING_ADDRESS_PENDING';
export const DELETE_USER_SHIPPING_ADDRESS_SUCCESS = 'ShippingAddresses/DELETE_USER_SHIPPING_ADDRESS_SUCCESS';
export const DELETE_USER_SHIPPING_ADDRESS_ERROR = 'ShippingAddresses/DELETE_USER_SHIPPING_ADDRESS_ERROR';
export const UPDATE_USER_SHIPPING_ADDRESS_PENDING = 'ShippingAddresses/UPDATE_USER_SHIPPING_ADDRESS_PENDING';
export const UPDATE_USER_SHIPPING_ADDRESS_SUCCESS = 'ShippingAddresses/UPDATE_USER_SHIPPING_ADDRESS_SUCCESS';
export const UPDATE_USER_SHIPPING_ADDRESS_ERROR = 'ShippingAddresses/UPDATE_USER_SHIPPING_ADDRESS_ERROR';

export const ADD_ACCOUNT_SHIPPING_ADDRESS_PENDING = 'ShippingAddresses/ADD_ACCOUNT_SHIPPING_ADDRESS_PENDING';
export const ADD_ACCOUNT_SHIPPING_ADDRESS_SUCCESS = 'ShippingAddresses/ADD_ACCOUNT_SHIPPING_ADDRESS_SUCCESS';
export const ADD_ACCOUNT_SHIPPING_ADDRESS_ERROR = 'ShippingAddresses/ADD_ACCOUNT_SHIPPING_ADDRESS_ERROR';

export const UPDATE_ACCOUNT_SHIPPING_ADDRESS_PENDING = 'ShippingAddresses/UPDATE_ACCOUNT_SHIPPING_ADDRESS_PENDING';
export const UPDATE_ACCOUNT_SHIPPING_ADDRESS_SUCCESS = 'ShippingAddresses/UPDATE_ACCOUNT_SHIPPING_ADDRESS_SUCCESS';
export const UPDATE_ACCOUNT_SHIPPING_ADDRESS_ERROR = 'ShippingAddresses/UPDATE_ACCOUNT_SHIPPING_ADDRESS_ERROR';

export const ADD_ADDRESS_BEING_PROCESSED = 'ShippingAddresses/ADD_ADDRESS_BEING_PROCESSED';
export const REMOVE_ADDRESS_BEING_PROCESSED = 'ShippingAddresses/REMOVE_ADDRESS_BEING_PROCESSED';

export const DELETE_ACCOUNT_SHIPPING_ADDRESS_CONFIRM = 'ShippingAddresses/DELETE_ACCOUNT_SHIPPING_ADDRESS_CONFIRM';
export const DELETE_ACCOUNT_SHIPPING_ADDRESS_PENDING = 'ShippingAddresses/DELETE_ACCOUNT_SHIPPING_ADDRESS_PENDING';
export const DELETE_ACCOUNT_SHIPPING_ADDRESS_SUCCESS = 'ShippingAddresses/DELETE_ACCOUNT_SHIPPING_ADDRESS_SUCCESS';
export const DELETE_ACCOUNT_SHIPPING_ADDRESS_ERROR = 'ShippingAddresses/DELETE_ACCOUNT_SHIPPING_ADDRESS_ERROR';

export const TRANSFER_AUTOSHIP_BETWEEN_ACCOUNT_ADDRESSES = 'ShippingAddresses/TRANSFER_AUTOSHIP_BETWEEN_ACCOUNT_ADDRESSES';
export const TRANSFER_AUTOSHIP_BETWEEN_USER_ADDRESSES = 'ShippingAddresses/TRANSFER_AUTOSHIP_BETWEEN_USER_ADDRESSES';

export const UPDATE_AUTOSHIPS_WITH_SHIPPING_ADDRESS = 'Autoships/UPDATE_AUTOSHIPS_WITH_SHIPPING_ADDRESS';

export const GET_COUNTRIES_PENDING = 'App/GET_COUNTRIES_PENDING';
export const GET_COUNTRIES_SUCCESS = 'App/GET_COUNTRIES_SUCCESS';
export const GET_COUNTRIES_ERROR = 'App/GET_COUNTRIES_ERROR';
export const GET_STATES_PENDING = 'App/GET_STATES_PENDING';
export const GET_STATES_SUCCESS = 'App/GET_STATES_SUCCESS';
export const GET_STATES_ERROR = 'App/GET_STATES_ERROR';
export const GET_MILITARY_LOCATIONS_PENDING = 'App/GET_MILITARY_LOCATIONS_PENDING';
export const GET_MILITARY_LOCATIONS_SUCCESS = 'App/GET_MILITARY_LOCATIONS_SUCCESS';
export const GET_MILITARY_LOCATIONS_ERROR = 'App/GET_MILITARY_LOCATIONS_ERROR';
export const SET_ADDRESS_SUGGESTIONS = 'App/SET_ADDRESS_SUGGESTIONS';
export const UPDATE_BREADCRUMBS = 'App/UPDATE_BREADCRUMBS';

//Footer Component
export const SUBMIT_NEWSLETTER_FORM_PENDING = 'Footer/NEWSLETTER_FORM_PENDING';
export const SUBMIT_NEWSLETTER_FORM_SUCCESS= 'Footer/NEWSLETTER_FORM_PENDING_SUCCESS';
export const SUBMIT_NEWSLETTER_FORM_ERROR = 'Footer/NEWSLETTER_FORM_ERROR';

// Payment Methods Page
export const GET_PAYMENT_METHODS_PENDING = 'PaymentMethods/GET_PENDING';
export const GET_PAYMENT_METHODS_SUCCESS = 'PaymentMethods/GET_SUCCESS';
export const GET_PAYMENT_METHODS_ERROR = 'PaymentMethods/GET_ERROR';

export const ADD_CREDIT_CARD_PENDING = 'PaymentMethods/ADD_CREDIT_CARD_PENDING';
export const ADD_CREDIT_CARD_SUCCESS = 'PaymentMethods/ADD_CREDIT_CARD_SUCCESS';
export const ADD_CREDIT_CARD_ERROR = 'PaymentMethods/ADD_CREDIT_CARD_ERROR';
export const UPDATE_CREDIT_CARD_PENDING = 'PaymentMethods/UPDATE_CREDIT_CARD_PENDING';
export const UPDATE_CREDIT_CARD_SUCCESS = 'PaymentMethods/UPDATE_CREDIT_CARD_SUCCESS';
export const UPDATE_CREDIT_CARD_ERROR = 'PaymentMethods/UPDATE_CREDIT_CARD_ERROR';
export const DELETE_CREDIT_CARD_PENDING = 'PaymentMethods/DELETE_CREDIT_CARD_PENDING';
export const DELETE_CREDIT_CARD_SUCCESS = 'PaymentMethods/DELETE_CREDIT_CARD_SUCCESS';
export const DELETE_CREDIT_CARD_ERROR = 'PaymentMethods/DELETE_CREDIT_CARD_ERROR';
export const DELETE_CREDIT_CARD_CONFIRM = 'PaymentMethods/DELETE_CREDIT_CARD_CONFIRM';
export const GET_GIFT_CARD_BALANCE_PENDING = 'PaymentMethods/GET_GIFT_CARD_BALANCE_PENDING';
export const GET_GIFT_CARD_BALANCE_SUCCESS = 'PaymentMethods/GET_GIFT_CARD_BALANCE_SUCCESS';
export const GET_GIFT_CARD_BALANCE_ERROR = 'PaymentMethods/GET_GIFT_CARD_BALANCE_ERROR';
export const SET_CURRENT_GIFT_CARD = 'PaymentMethods/SET_CURRENT_GIFT_CARD';
export const GET_PAYMENT_TOKEN_PENDING = 'PaymentMethods/GET_PAYMENT_TOKEN_PENDING';
export const GET_PAYMENT_TOKEN_SUCCESS = 'PaymentMethods/GET_PAYMENT_TOKEN_SUCCESS';
export const GET_PAYMENT_INFO_PENDING = 'PaymentMethods/GET_PAYMENT_INFO_PENDING';
export const GET_PAYMENT_INFO_SUCCESS = 'PaymentMethods/GET_PAYMENT_INFO_SUCCESS';

export const DELETE_PAYPAL_ACCOUNT_PENDING = 'PaymentMethods/DELETE_PAYPAL_ACCOUNT_PENDING';
export const DELETE_PAYPAL_ACCOUNT_SUCCESS = 'PaymentMethods/DELETE_PAYPAL_ACCOUNT_SUCCESS';
export const DELETE_PAYPAL_ACCOUNT_ERROR = 'PaymentMethods/DELETE_PAYPAL_ACCOUNT_ERROR';
export const DELETE_PAYPAL_ACCOUNT_CONFIRM = 'PaymentMethods/DELETE_PAYPAL_ACCOUNT_CONFIRM';

export const ASSIGN_DEFAULT_PAYMENT_METHOD_PENDING = 'PaymentMethods/ASSIGN_DEFAULT_PENDING';
export const ASSIGN_DEFAULT_PAYMENT_METHOD_SUCCESS = 'PaymentMethods/ASSIGN_DEFAULT_SUCCESS';
export const ASSIGN_DEFAULT_PAYMENT_METHOD_ERROR = 'PaymentMethods/ASSIGN_DEFAULT_ERROR';

// Org Management / Billing LIFEWAY_ACCOUNTS_URL
export const ADD_BILLING_ACCT_ASSOC_PENDING = 'OrganizationManagement/ADD_BILLING_ACCT_ASSOC_PENDING';
export const ADD_BILLING_ACCT_ASSOC_SUCCESS = 'OrganizationManagement/ADD_BILLING_ACCT_ASSOC_SUCCESS';
export const ADD_BILLING_ACCT_ASSOC_ERROR = 'OrganizationManagement/ADD_BILLING_ACCT_ASSOC_ERROR';
export const GET_BILLING_ACCS_PENDING = 'OrganizationManagement/GET_BILLING_ACCT_PENDING';
export const GET_BILLING_ACCS_SUCCESS = 'OrganizationManagement/GET_BILLING_ACCT_SUCCESS';
export const GET_BILLING_ACCS_ERROR = 'OrganizationManagement/GET_BILLING_ACCT_ERROR';
export const GET_LINKED_ORGS_PENDING = 'OrganizationManagement/GET_LINKED_ORGS_PENDING';
export const GET_LINKED_ORGS_SUCCESS = 'OrganizationManagement/GET_LINKED_ORGS_SUCCESS';
export const GET_LINKED_ORGS_ERROR = 'OrganizationManagement/GET_LINKED_ORGS_ERROR';
export const UNLINK_ORG_CONFIRM = 'OrganizationManagement/UNLINK_ORG_CONFIRM';
export const UNLINK_ORG_PENDING = 'OrganizationManagement/UNLINK_ORG_PENDING';
export const UNLINK_ORG_SUCCESS = 'OrganizationManagement/UNLINK_ORG_SUCCESS';
export const UNLINK_ORG_ERROR = 'OrganizationManagement/UNLINK_ORG_ERROR';
export const REVALIDATE_ORG_LINK_PENDING = 'OrganizationManagement/REVALIDATE_ORG_LINK_PENDING';
export const REVALIDATE_ORG_LINK_SUCCESS = 'OrganizationManagement/REVALIDATE_ORG_LINK_SUCCESS';
export const REVALIDATE_ORG_LINK_ERROR = 'OrganizationManagement/REVALIDATE_ORG_LINK_ERROR';
export const GET_LINKED_ORG_GROUPS_PENDING = 'OrganizationManagement/GET_LINKED_ORG_GROUPS_PENDING';
export const GET_LINKED_ORG_GROUPS_SUCCESS = 'OrganizationManagement/GET_LINKED_ORG_GROUPS_SUCCESS';
export const GET_LINKED_ORG_GROUPS_ERROR = 'OrganizationManagement/GET_LINKED_ORG_GROUPS_ERROR';
export const CLAIM_LINKED_ORG_ADMIN_PENDING = 'OrganizationManagement/CLAIM_LINKED_ORG_ADMIN_PENDING';
export const CLAIM_LINKED_ORG_ADMIN_SUCCESS = 'OrganizationManagement/CLAIM_LINKED_ORG_ADMIN_SUCCESS';
export const CLAIM_LINKED_ORG_ADMIN_ERROR = 'OrganizationManagement/CLAIM_LINKED_ORG_ADMIN_ERROR';
export const ADD_USER_TO_ORG_GROUP_PENDING = 'OrganizationManagement/ADD_USER_TO_ORG_GROUP_PENDING';
export const ADD_USER_TO_ORG_GROUP_SUCCESS = 'OrganizationManagement/ADD_USER_TO_ORG_GROUP_SUCCESS';
export const ADD_USER_TO_ORG_GROUP_ERROR = 'OrganizationManagement/ADD_USER_TO_ORG_GROUP_ERROR';
export const REMOVE_USER_FROM_ORG_GROUP_CONFIRM = 'OrganizationManagement/REMOVE_USER_FROM_ORG_GROUP_CONFIRM';
export const REMOVE_USER_FROM_ORG_GROUP_PENDING = 'OrganizationManagement/REMOVE_USER_FROM_ORG_GROUP_PENDING';
export const REMOVE_USER_FROM_ORG_GROUP_SUCCESS = 'OrganizationManagement/REMOVE_USER_FROM_ORG_GROUP_SUCCESS';
export const REMOVE_USER_FROM_ORG_GROUP_ERROR = 'OrganizationManagement/REMOVE_USER_FROM_ORG_GROUP_ERROR';

export const INVITE_APPROVED_PURCHASER_PENDING = 'OrganizationManagement/INVITE_APPROVED_PURCHASER_PENDING';
export const REMOVE_APPROVED_PURCHASER_PENDING = 'OrganizationManagement/REMOVE_APPROVED_PURCHASER_PENDING';
export const REMOVE_APPROVED_PURCHASER_SUCCESS = 'OrganizationManagement/REMOVE_APPROVED_PURCHASER_SUCCESS';

export const DELETE_BILLING_ACCT_ASSOC_PENDING = 'OrganizationManagement/DELETE_BILLING_ACCT_ASSOC_PENDING';
export const DELETE_BILLING_ACCT_ASSOC_SUCCESS = 'OrganizationManagement/DELETE_BILLING_ACCT_ASSOC_SUCCESS';
export const DELETE_BILLING_ACCT_ASSOC_ERROR = 'OrganizationManagement/DELETE_BILLING_ACCT_ASSOC_ERROR';
export const UPDATE_BILLING_ACCT_ASSOC_PENDING = 'OrganizationManagement/UPDATE_BILLING_ACCT_ASSOC_PENDING';
export const UPDATE_BILLING_ACCT_ASSOC_SUCCESS = 'OrganizationManagement/UPDATE_BILLING_ACCT_ASSOC_SUCCESS';
export const UPDATE_BILLING_ACCT_ASSOC_ERROR = 'OrganizationManagement/UPDATE_BILLING_ACCT_ASSOC_ERROR';
export const DELETE_BILLING_ACCT_ASSOC_CONFIRM = 'OrganizationManagement/DELETE_BILLING_ACCT_ASSOC_CONFIRM';
export const SET_CURRENT_ORG_ID = 'OrganizationManagement/SET_CURRENT_ORG_ID';
export const PIN_MANAGEMENT_REQUEST = 'OrganizationManagement/PIN_MANAGEMENT_REQUEST';
export const FORGOT_PIN_REQUEST_PENDING = 'OrganizationManagement/FORGOT_PIN_REQUEST_PENDING';
export const NEW_PIN_REQUEST_PENDING = 'OrganizationManagement/NEW_PIN_REQUEST_PENDING';

export const ADD_PENDING_TAX_EXEMPTION = 'OrganizationManagement/ADD_PENDING_TAX_EXEMPTION';

export const GET_DEFAULT_BILLING_ACCOUNT_ID_PENDING = 'OrganizationManagement/GET_DEFAULT_BILLING_ACCOUNT_ID_PENDING';
export const GET_DEFAULT_BILLING_ACCOUNT_ID_SUCCESS = 'OrganizationManagement/GET_DEFAULT_BILLING_ACCOUNT_ID_SUCCESS';
export const GET_DEFAULT_BILLING_ACCOUNT_ID_ERROR = 'OrganizationManagement/GET_DEFAULT_BILLING_ACCOUNT_ID_ERROR';

export const GET_ORGANIZATION_MEMBER_ROLES_PENDING = 'OrganizationManagement/GET_ORGANIZATION_MEMBER_ROLES_PENDING';
export const GET_ORGANIZATION_MEMBER_ROLES_SUCCESS = 'OrganizationManagement/GET_ORGANIZATION_MEMBER_ROLES_SUCCESS';
export const GET_ORGANIZATION_MEMBER_ROLES_ERROR = 'OrganizationManagement/GET_ORGANIZATION_MEMBER_ROLES_ERROR';

export const GET_USER_ROLES_PENDING = 'OrganizationManagement/GET_USER_ROLES_PENDING';
export const GET_USER_ROLES_SUCCESS = 'OrganizationManagement/GET_USER_ROLES_SUCCESS';
export const GET_USER_ROLES_ERROR = 'OrganizationManagement/GET_USER_ROLES_ERROR';

export const ADD_USER_TO_ORG_ROLE_PENDING = 'OrganizationManagement/ADD_USER_TO_ORG_ROLE_PENDING';
export const ADD_USER_TO_ORG_ROLE_SUCCESS = 'OrganizationManagement/ADD_USER_TO_ORG_ROLE_SUCCESS';
export const ADD_USER_TO_ORG_ROLE_ERROR = 'OrganizationManagement/ADD_USER_TO_ORG_ROLE_ERROR';

export const REMOVE_ROLE_CONFIRM = 'OrganizationManagement/REMOVE_ROLE_CONFIRM'; 
export const REMOVE_ROLE_PENDING = 'OrganizationManagement/REMOVE_ROLE_PENDING';
export const REMOVE_ROLE_SUCCESS = 'OrganizationManagement/REMOVE_ROLE_SUCCESS';
export const REMOVE_ROLE_ERROR = 'OrganizationManagement/REMOVE_ROLE_ERROR';

export const GET_CUSTOMER_PERMISSIONS_PENDING = 'OrganizationManagement/GET_CUSTOMER_PERMISSIONS_PENDING';
export const GET_CUSTOMER_PERMISSIONS_SUCCESS = 'OrganizationManagement/GET_CUSTOMER_PERMISSIONS_SUCCESS';
export const GET_CUSTOMER_PERMISSIONS_ERROR = 'OrganizationManagement/GET_CUSTOMER_PERMISSIONS_ERROR';

// Order History
export const GET_ORDER_DETAILS_PENDING = 'OrderHistory/GET_ORDER_DETAILS_PENDING';
export const GET_ORDER_DETAILS_SUCCESS = 'OrderHistory/GET_ORDER_DETAILS_SUCCESS';
export const GET_ORDER_DETAILS_ERROR = 'OrderHistory/GET_ORDER_DETAILS_ERROR';
export const GET_INVOICES_SHIPMENTS_PENDING = 'OrderHistory/GET_INVOICES_SHIPMENTS_PENDING';
export const GET_INVOICES_SHIPMENTS_SUCCESS = 'OrderHistory/GET_INVOICES_SHIPMENTS_SUCCESS';
export const GET_INVOICES_SHIPMENTS_ERROR = 'OrderHistory/GET_INVOICES_SHIPMENTS_ERROR';
export const GET_ORDERS_PENDING = 'OrderHistory/GET_ORDERS_PENDING';
export const GET_ORDERS_SUCCESS = 'OrderHistory/GET_ORDERS_SUCCESS';
export const GET_ORDERS_ERROR = 'OrderHistory/GET_ORDERS_ERROR';
export const GET_PROCESSED_ORDERS_PENDING = 'OrderHistory/GET_PROCESSED_ORDERS_PENDING';
export const GET_PROCESSED_ORDERS_SUCCESS = 'OrderHistory/GET_PROCESSED_ORDERS_SUCCESS';
export const GET_PROCESSED_ORDERS_ERROR = 'OrderHistory/GET_PROCESSED_ORDERS_ERROR';
export const GET_PENDING_ORDERS_PENDING = 'OrderHistory/GET_PENDING_ORDERS_PENDING';
export const GET_PENDING_ORDERS_SUCCESS = 'OrderHistory/GET_PENDING_ORDERS_SUCCESS';
export const GET_PENDING_ORDERS_ERROR = 'OrderHistory/GET_PENDING_ORDERS_ERROR';
export const GET_PENDING_ORDER_IMAGES_PENDING = 'OrderHistory/GET_PENDING_ORDER_IMAGES_PENDING';
export const GET_PENDING_ORDER_IMAGES_SUCCESS = 'OrderHistory/GET_PENDING_ORDER_IMAGES_SUCCESS';
export const GET_PENDING_ORDER_IMAGES_ERROR = 'OrderHistory/GET_PENDING_ORDER_IMAGES_ERROR';
export const GET_PROCESSED_ORDER_IMAGES_PENDING = 'OrderHistory/GET_PROCESSED_ORDER_IMAGES_PENDING';
export const GET_PROCESSED_ORDER_IMAGES_SUCCESS = 'OrderHistory/GET_PROCESSED_ORDER_IMAGES_SUCCESS';
export const GET_PROCESSED_ORDER_IMAGES_ERROR = 'OrderHistory/GET_PROCESSED_ORDER_IMAGES_ERROR';
export const GET_ORDER_IMAGES_PENDING = 'OrderHistory/GET_ORDER_IMAGES_PENDING';
export const GET_ORDER_IMAGES_SUCCESS = 'OrderHistory/GET_ORDER_IMAGES_SUCCESS';
export const GET_ORDER_IMAGES_ERROR = 'OrderHistory/GET_ORDER_IMAGES_ERROR';
export const SET_IS_ORDER_IMAGE_LOADING = 'OrderHistory/SET_IS_ORDER_IMAGE_LOADING';
export const GET_SEARCHED_ORDER_IMAGES_PENDING = 'OrderHistory/GET_SEARCHED_ORDER_IMAGES_PENDING';
export const GET_SEARCHED_ORDER_IMAGES_SUCCESS = 'OrderHistory/GET_SEARCHED_ORDER_IMAGES_SUCCESS';
export const GET_SEARCHED_ORDER_IMAGES_ERROR = 'OrderHistory/GET_SEARCHED_ORDER_IMAGES_ERROR';
export const GET_ORDER_SEARCH_PENDING = 'OrderHistory/GET_ORDER_SEARCH_PENDING';
export const GET_ORDER_SEARCH_SUCCESS = 'OrderHistory/GET_ORDER_SEARCH_SUCCESS';
export const GET_ORDER_SEARCH_ERROR = 'OrderHistory/GET_ORDER_SEARCH_ERROR';

export const ADD_TO_CART_PENDING = 'Cart/ADD_TO_CART_PENDING';
export const ADD_TO_CART_SUCCESS = 'Cart/ADD_TO_CART_SUCCESS';
export const ADD_TO_CART_ERROR = 'Cart/ADD_TO_CART_ERROR';

// Account Activity
export const GET_ACCT_BALANCE_PENDING = 'AccountActivity/GET_ACCT_BALANCE_PENDING';
export const GET_ACCT_BALANCE_SUCCESS = 'AccountActivity/GET_ACCT_BALANCE_SUCCESS';
export const GET_ACCT_BALANCE_ERROR = 'AccountActivity/GET_ACCT_BALANCE_ERROR';

export const GET_ACCT_STMTS_PENDING = 'AccountActivity/GET_ACCT_STMT_PENDING';
export const GET_ACCT_STMTS_SUCCESS = 'AccountActivity/GET_ACCT_STMT_SUCCESS';
export const GET_ACCT_STMTS_ERROR = 'AccountActivity/GET_ACCT_STMT_ERROR';

export const GET_ACCT_TRANSACTIONS_PENDING = 'AccountActivity/GET_ACCT_TRANSACTIONS_PENDING';
export const GET_ACCT_TRANSACTIONS_SUCCESS = 'AccountActivity/GET_ACCT_TRANSACTIONS_SUCCESS';
export const GET_ACCT_TRANSACTIONS_ERROR = 'AccountActivity/GET_ACCT_TRANSACTIONS_ERROR';
export const SET_TRANSACTIONS_CHECKED_STATE = 'AccountActivity/SET_TRANSACTIONS_CHECKED_STATE';
export const RESET_TRANSACTIONS_CHECKED_STATE = 'AccountActivity/RESET_TRANSACTIONS_CHECKED_STATE';

export const DOWNLOAD_STATEMENT_PENDING = 'AccountActivity/DOWNLOAD_STATEMENT_PENDING';
export const DOWNLOAD_STATEMENT_SUCCESS = 'AccountActivity/DOWNLOAD_STATEMENT_SUCCESS';
export const DOWNLOAD_STATEMENT_ERROR = 'AccountActivity/DOWNLOAD_STATEMENT_ERROR';

export const FETCH_STATEMENT_PDF_PENDING = 'AccountActivity/FETCH_STATEMENT_PDF_PENDING';
export const FETCH_STATEMENT_PDF_SUCCESS = 'AccountActivity/FETCH_STATEMENT_PDF_SUCCESS';
export const FETCH_STATEMENT_PDF_ERROR = 'AccountActivity/FETCH_STATEMENT_PDF_ERROR';

// Digital Media /////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////
export const GET_DIGITAL_MEDIA_PENDING = 'DigitalMedia/GET_DIGITAL_MEDIA_PENDING';
export const GET_DIGITAL_MEDIA_SUCCESS = 'DigitalMedia/GET_DIGITAL_MEDIA_SUCCESS';
export const GET_DIGITAL_MEDIA_ERROR = 'DigitalMedia/GET_DIGITAL_MEDIA_ERROR';
export const SET_DIGITAL_MEDIA_STATUS = 'DigitalMedia/SET_DIGITAL_MEDIA_STATUS';

// Product Data
export const FETCH_PRODUCT_IMAGE_URLS = 'Products/FETCH_PRODUCT_IMAGE_URLS';
export const FETCH_PRODUCT_IMAGE_URLS_SUCCESS = 'Products/FETCH_PRODUCT_IMAGE_URLS_SUCCESS';
export const FETCH_PRODUCT_IMAGE_URLS_ERROR = 'Products/FETCH_PRODUCT_IMAGE_URLS_ERROR';
export const SET_PRODUCT_IMAGES_LOADING = 'Products/SET_PRODUCT_IMAGES_LOADING';
export const GET_PRODUCTS_PENDING = 'Products/GET_PRODUCTS_PENDING';
export const GET_PRODUCTS_SUCCESS = 'Products/GET_PRODUCTS_SUCCESS';
export const GET_PRODUCTS_ERROR = 'Products/GET_PRODUCTS_ERROR';

// Product Resources
export const GET_RESOURCES_PENDING = 'DigitalMedia/GET_RESOURCE_PENDING';
export const GET_RESOURCES_SUCCESS = 'DigitalMedia/GET_RESOURCE_SUCCESS';
export const GET_RESOURCES_ERROR = 'DigitalMedia/GET_RESOURCE_ERROR';

// Download URLS
export const GET_DOWNLOADS_PENDING = 'DigitalMedia/GET_DOWNLOADS_PENDING';
export const GET_DOWNLOADS_SUCCESS = 'DigitalMedia/GET_DOWNLOADS_SUCCESS';
export const GET_DOWNLOADS_ERROR = 'DigitalMedia/GET_DOWNLOADS_ERROR';
export const CLEAR_MULTI_DOWNLOADS = 'DigitalMedia/CLEAR_MULTI_DOWNLOADS';

export const GET_MEDIA_CONTENT_CHILDREN_PENDING = 'DigitalMedia/GET_MEDIA_CONTENT_CHILDREN_PENDING';
export const GET_MEDIA_CONTENT_CHILDREN_SUCCESS = 'DigitalMedia/GET_MEDIA_CONTENT_CHILDREN_SUCCESS';
export const GET_MEDIA_CONTENT_CHILDREN_ERROR = 'DigitalMedia/GET_MEDIA_CONTENT_CHILDREN_ERROR';

export const QUERY_MEDIA_LOCATIONS_PENDING = 'DigitalMedia/QUERY_MEDIA_LOCATIONS_PENDING';
export const QUERY_MEDIA_LOCATIONS_SUCCESS = 'DigitalMedia/QUERY_MEDIA_LOCATIONS_SUCCESS';
export const QUERY_MEDIA_LOCATIONS_ERROR = 'DigitalMedia/QUERY_MEDIA_LOCATIONS_ERROR';

export const SEARCH_MEDIA_PENDING = 'DigitalMedia/SEARCH_PENDING';
export const SEARCH_MEDIA_SUCCESS = 'DigitalMedia/SEARCH_SUCCESS';

export const GET_LICENSES_PENDING = 'LicenseAccess/GET_LICENSES_PENDING';
export const GET_LICENSES_SUCCESS = 'LicenseAccess/GET_LICENSES_SUCCESS';
export const GET_LICENSES_ERROR = 'LicenseAccess/GET_LICENSES_ERROR';

export const GET_LICENSE_PENDING = 'LicenseAccess/GET_LICENSE_PENDING';
export const GET_LICENSE_SUCCESS = 'LicenseAccess/GET_LICENSE_SUCCESS';
export const GET_LICENSE_ERROR = 'LicenseAccess/GET_LICENSE_ERROR';

export const REVOKE_SEAT_PENDING = 'LicenseAccess/REVOKE_SEAT_PENDING';
export const REVOKE_SEAT_SUCCESS = 'LicenseAccess/REVOKE_SEAT_SUCCESS';
export const REVOKE_SEAT_ERROR = 'LicenseAccess/REVOKE_SEAT_ERROR';

export const GRANT_SEAT_PENDING = 'LicenseAccess/GRANT_SEAT_PENDING';
export const GRANT_SEAT_SUCCESS = 'LicenseAccess/GRANT_SEAT_SUCCESS';
export const GRANT_SEAT_ERROR = 'LicenseAccess/GRANT_SEAT_ERROR';

export const GRANT_ACCESS_ACCOUNT_CHECK = 'LicenseAccess/GRANT_ACCESS_ACCOUNT_CHECK';

export const CONFIRM_REVOKE_SEAT = 'LicenseAccess/CONFIRM_REVOKE_SEAT';

export const ADD_MANAGER_PENDING = 'LicenseAccess/ADD_MANAGER_PENDING';
export const ADD_MANAGER_SUCCESS = 'LicenseAccess/ADD_MANAGER_SUCCESS';
export const ADD_MANAGER_ERROR = 'LicenseAccess/ADD_MANAGER_ERROR';

export const REMOVE_MANAGER_PENDING = 'LicenseAccess/REMOVE_MANAGER_PENDING';
export const REMOVE_MANAGER_SUCCESS = 'LicenseAccess/REMOVE_MANAGER_SUCCESS';
export const REMOVE_MANAGER_ERROR = 'LicenseAccess/REMOVE_MANAGER_ERROR';

export const CLEAR_REVOKE_ERROR = 'LicenseAccess/CLEAR_REVOKE_ERROR';

export const SEARCH_REDEEMED_MEDIA_PENDING = 'DigitalMedia/SEARCH_REDEEMED_PENDING';

export const MEDIA_TYPES = {
  all: {
    label: 'Show All Content Types',
    value: 'show-all-content-types'
  },
  downloads: {
    label: 'Downloads',
    value: 'downloads'
  },
  ebooks: {
    label: 'eBooks',
    value: 'e-books'
  },
  videos: {
    label: 'Videos',
    value: 'rentals'
  },
  simulcasts: {
    label: 'Simulcasts',
    value: 'simulcasts'
  },
  memberships: {
    label: 'Memberships',
    value: 'memberships'
  },
  otherContent: {
    label: 'All Other Content',
    value: 'other-content'
  },
};

export const MODALS = {
  PIN_MANAGEMENT_MODAL: 'pinManagementModal',
  GIFTCARD_BALANCE_MODAL: 'giftCardBalance',
  EMAIL_GIFTCARD_MODAL: 'emailGiftCard',
  EBOOKS_MODAL: 'ebook'
};

export const PIN_FORM_TYPES = {
  FORGOT: 'forgot',
  NEW: 'new'
};

export const SUBSCRIPTION_PAYMENT_TYPES = {
  CREDIT_CARD: 'creditCard',
  ACCOUNT: 'account'
};

export const PATH_DIGITAL_MEDIA_DOWNLOADS = `${PATH_DIGITAL_MEDIA}/${MEDIA_TYPES.downloads.value}`;
export const PATH_DIGITAL_MEDIA_EBOOKS = `${PATH_DIGITAL_MEDIA}/${MEDIA_TYPES.ebooks.value}`;
export const PATH_DIGITAL_MEDIA_VIDEOS = `${PATH_DIGITAL_MEDIA}/${MEDIA_TYPES.videos.value}`;
export const PATH_DIGITAL_MEDIA_OTHER = `${PATH_DIGITAL_MEDIA}/${MEDIA_TYPES.otherContent.value}`;
export const PATH_DIGITAL_MEDIA_SIMULCASTS = `${PATH_DIGITAL_MEDIA}/${MEDIA_TYPES.simulcasts.value}`;
export const PATH_DIGITAL_MEDIA_MEMBERSHIPS = `${PATH_DIGITAL_MEDIA}/${MEDIA_TYPES.memberships.value}`;

export const PATH_DIGITAL_MEDIA_REDEEMED = `${PATH_DIGITAL_MEDIA}/redeemed`;
/////////////////////////////////////////////////////////////////////////////////

// Gift Cards Page
export const GET_GIFT_CARD_PURCHASES_PENDING = 'GiftCards/GET_GIFT_CARD_PURCHASES_PENDING';
export const GET_GIFT_CARD_PURCHASES_SUCCESS = 'GiftCards/GET_GIFT_CARD_PURCHASES_SUCCESS';
export const GET_GIFT_CARD_PURCHASES_ERROR = 'GiftCards/GET_GIFT_CARD_PURCHASES_ERROR';
export const UPDATE_GIFT_CARD_BALANCE = 'GiftCards/UPDATE_GIFT_CARD_BALANCE';
export const EMAIL_GIFT_CARD_PENDING = 'GiftCards/EMAIL_GIFT_CARD_PENDING';
export const EMAIL_GIFT_CARD_SUCCESS = 'GiftCards/EMAIL_GIFT_CARD_SUCCESS';
export const EMAIL_GIFT_CARD_ERROR = 'GiftCards/EMAIL_GIFT_CARD_ERROR';
/////////////////////////////////////////////////////////////////////////////////

// Redeem Code Page
export const GET_CODES_PENDING = 'RedeemCode/GET_CODES_PENDING';
export const GET_CODE_SUCCESS = 'RedeemCode/GET_CODE_SUCCESS';
export const GET_CODE_ERROR = 'RedeemCode/GET_CODE_ERROR';

// Table constants
export const TABLE_ON_INIT_COLUMNS = 'Table/ON_INIT_COLUMNS';
export const TABLE_UPDATE_SETTINGS = 'Table/UPDATE_SETTINGS';
export const TABLE_APPEND_ROW_DATA = 'Table/APPEND_ROW_DATA';

// Form constants
export const VALIDATE_PIN_FORM = 'validatePinForm';
export const BILLING_ACCT_ASSOC_FORM = 'BillingAcctAssocForm';
export const UPDATE_FORM_STATE = 'Form/UPDATE_FORM_STATE';
export const CLEAR_FORM_STATE = 'Form/CLEAR_FORM_STATE';
export const SHARE_ACCESS_BY_EMAIL_FORM = 'ShareAccessByEmailForm';

// URL's
export const SET_REDIRECT_URL = 'App/SET_REDIRECT_URL';

// Other
export const DATE_FORMAT = 'MM/DD/YYYY';
export const PARSE_DATE_FORMAT = ['M/D/YYYY', 'M/D/YY'];
export const DEFAULT_MANAGE_ACCESS_MEDIA_TYPE = 'simulcasts';
export const IS_IMPERSONATION_SESSION ='isImpersonationSession';

export const PRODUCT_AVAILABILITY_MESSAGES = {
  'product.availability.shortdescription.nyp': 'Not Yet Published',
  'product.availability.shortdescription.instock': 'In Stock',
  'product.availability.shortdescription.backordered': 'Backordered',
  'product.availability.shortdescription.outstock': 'Out of Stock',
  'product.availability.shortdescription.immediate': 'Immediately Available',
  unknown: 'Unknown'
};

// Algolia Search API
export const CURRENT_ENV = ENV_LOCATION.includes('local') ? 'int' : ENV_LOCATION.includes('int') ? 'int' : ENV_LOCATION.includes('uat') ? 'uat' : 'prod';
export const ALGOLIA_SEARCH_APP_ID = 'SSDQWUEVB6';
export const ALGOLIA_SEARCH_API_KEY = 'a4bc3e5ac605f371cdf83fa2484655f2';
export const ALGOLIA_BASE_PRODUCT_INDEX = `${CURRENT_ENV}.taxonomy.lwc`;

export const LAST_AUTOSHIP_ELIGIBLE_CHO_DATES = [
  {
    date: '2022-01-06T00:00:00.000-04:00',
    quarter: 'Winter 2022',
  },
  {
    date: '2022-03-24T00:00:00.000-04:00',
    quarter: 'Spring 2022',
  },
  {
    date: '2022-06-23T00:00:00.000-04:00',
    quarter: 'Summer 2022',
  },
  {
    date: '2022-09-22T00:00:00.000-04:00',
    quarter: 'Fall 2022',
  },
  {
    date: '2023-01-05T00:00:00.000-04:00',
    quarter: 'Winter 2023',
  },
  {
    date: '2023-03-20T00:00:00.000-04:00',
    quarter: 'Spring 2023',
  },
  {
    date: '2023-06-29T00:00:00.000-04:00',
    quarter: 'Summer 2023',
  },
  {
    date: '2023-09-28T00:00:00.000-04:00',
    quarter: 'Fall 2023',
  },
  {
    date: '2023-12-29T00:00:00.000-04:00',
    quarter: 'Winter 2024',
  },
  {
    date: '2024-03-24T00:00:00.000-04:00',
    quarter: 'Spring 2024',
  },
  {
    date: '2024-06-21T00:00:00.000-04:00',
    quarter: 'Summer 2024',
  },
  {
    date: '2024-09-28T00:00:00.000-04:00',
    quarter: 'Fall 2024',
  },
];
